// Imports


// Plugins
const plugins = [{
  location: "/Users/philipp/dev/this-week-in-react",
  plugins: [],
  hooks: {}
}]

// Export em!
export default plugins